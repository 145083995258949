<!-- <template>
  <section> -->
    <!--是否登录-->
    <!-- <login-welcome /> -->

    <!-- 今日赠言
    <tip-card />

    资源推介-->
    <!-- <PromotionCard /> -->
  <!-- </section>
</template> -->
<template>
  <!-- 图片展示栏 -->
  <section>
  
    <div>
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item index="1" class="menu-item">
        <i class="el-icon-location"></i> 基层党建
      </el-menu-item>
      <el-menu-item index="2" class="menu-item">
        <i class="el-icon-message"></i> 民情民意
      </el-menu-item>
      <el-menu-item index="3" class="menu-item">
        <i class="el-icon-date"></i> 预约办事
      </el-menu-item>
      <el-menu-item index="4" class="menu-item">
        <i class="el-icon-user-solid"></i> 党员双报到
      </el-menu-item>
    </el-menu>
    
  </div>
  <div class="box">
      <img src="@/assets/image/xs.png" alt="图片展示">
    </div>
    <div class="box">
      <img src="@/assets/image/lj.jpg" alt="图片展示">
    </div>
</section>
</template>

<script>
// import TipCard from '@/views/card/Tip'
// import PromotionCard from '@/views/card/Promotion'
// import LoginWelcome from '@/views/card/LoginWelcome'

// export default {
//   name: 'CardBar',
//   components: { LoginWelcome, PromotionCard, TipCard }
// }
export default {
  data() {
    return {
      activeIndex: '1' // 默认选中第一个
    };
  },
  methods: {
    handleSelect(index) {
      // 根据选中的index跳转到对应的页面
      switch (index) {
        case '1':
          this.$router.push('/member/verify/resident'); // 如果使用了Vue Router，可以通过路由跳转
          
          break;
        case '2':
          this.$router.push('/publicOpinion');
          
          break;
        case '3':
          this.$router.push('/book');
          
          break;
        case '4':
          this.$router.push('/member/verify/partyMember');
          
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style scoped>
/* 样式可以根据需求自行调整 */
.el-menu-demo {
  margin-bottom: 20px;
}
.menu-item {
  width: 50%;
}
</style>

<!-- // <style scoped>
// </style> -->
